import { useFormik } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import LogoWithText from '../components/logoWithText';
import { useAuth } from '../lib/context/auth';

const Login: React.FC = () => {
    const { login } = useAuth();
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Required'),
            password: Yup.string().min(6, 'Password must be at least 6 characters').required('Required'),
        }),
        onSubmit: async (values) => {
            try {
                await login(values.email, values.password);
            } catch (error) {
                console.error(error);
                alert('Login failed.');
            }
        },
    });

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 flex-col">
            <LogoWithText />
            <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
                <h1 className="text-xl font-bold mb-4">Login</h1>
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            className="mt-1 p-2 border border-gray-300 rounded w-full"
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
                        ) : null}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            className="mt-1 p-2 border border-gray-300 rounded w-full"
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.password}</div>
                        ) : null}
                    </div>
                    <button type="submit" className="w-full py-2 px-4 bg-black text-white rounded hover:bg-gray-700">Login</button>
                </form>
                <div className="mt-4 flex justify-between">
                    <Link to="/register" className="text-sm text-blue-500">Create an account</Link>
                    <Link to="/forgot-password" className="text-sm text-blue-500">Forgot Password?</Link>
                </div>
            </div>
        </div>
    );
};

export default Login;
