import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { initFacebookSdk, loginWithFacebook, logoutFacebook } from '../services/facebook';


interface FacebookAuthContextType {
  userData: {
    accessCode: string,
    wabaId: string,
    phoneNumber_id: string;
    errorMessage: string
  }
  resetUserData: () => void;
  login: () => void;
  logout: () => void;
}

const FacebookAuthContext = createContext<FacebookAuthContextType | null>(null);


export const FacebookAuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

  const [userData, setUserData] = useState({
    accessCode: '',
    wabaId: '',
    phoneNumber_id: '',
    errorMessage: ''
  })

  const sessionInfoListener = (event: any) => {
    if (event.origin !== "https://www.facebook.com" && event.origin !== "https://web.facebook.com") {
        return;
    }
    try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
            // if user finishes the Embedded Signup flow
            if (data.event === 'FINISH') {
                const { phone_number_id = '', waba_id = '' } = data.data;
                console.log(`Listener :: WABA Connect Success`);
                console.log("Listener :: Phone number ID ", phone_number_id, " WhatsApp business account ID ", waba_id);
                setUserData((prevState) => {
                  return {
                    ...prevState,
                    phoneNumber_id: phone_number_id,
                    wabaId: waba_id
                  }
                })
                // if user cancels the Embedded Signup flow
            } else if (data.event === 'FINISH_ONLY_WABA') {
                const { phone_number_id = '', waba_id = '' } = data.data;
                console.log(`Listener :: WABA Connect Success - Only WABA`);
                console.log("Listener :: Phone number ID ", phone_number_id, " WhatsApp business account ID ", waba_id);
                setUserData((prevState) => {
                  return {
                    ...prevState,
                    phoneNumber_id: phone_number_id,
                    wabaId: waba_id
                  }
                })
            } else if (data.event === 'CANCEL') {
                const { current_step } = data.data;
                console.warn("Listener :: WABA Connect SignUp Cancelled at ", current_step);
                // if user reports an error during the Embedded Signup flow
                setUserData((prevState) => {
                  return {
                    ...prevState,
                    errorMessage: 'Account linking cancelled.'
                  }
                })
            } else if (data.event === 'ERROR') {
                const { error_message } = data.data;
                console.error("Listener :: WABA Connect SignUp error ", error_message);
                setUserData((prevState) => {
                  return {
                    ...prevState,
                    errorMessage: 'Error adding WhatsApp Business Account. Please contact support for help!'
                  }
                })
            }
        }
    } catch {
        console.log('Non JSON Responses', event.data);
    }
}

  const login = async () => {

    try {
      resetUserData();
      const response = await loginWithFacebook();
      // console.log(`Received response :: ${JSON.stringify(response)}`);
      const code = response?.authResponse?.code
      if (response.status === 'connected') {
        setUserData((prevState) => {
          // console.log(`Connected :: Set data called with code :: ${code}`)
          return {
            ...prevState,
            accessCode: code
          }
        })
        console.log('User authenticated successfully...')
      } else if(response.status === 'unknown') {
        if(code) {
          setUserData((prevState) => {
            // console.log(`Unknown :: Set data called with code :: ${code}`)
            return {
              ...prevState,
              accessCode: code
            }
          })
        }
      } else {
        console.warn('User authenticatio failed...')
      }
    } catch (error) {
      console.error('Facebook login error:', error);
      setUserData((prevState) => {
        return {
          ...prevState,
          errorMessage: 'Error adding WhatsApp Business Account. Please contact support for help!'
        }
      })
    }
  };

  const logout = async () => {
    await logoutFacebook();
  };

  const resetUserData = () => {
    setUserData({
      accessCode: '',
      wabaId: '',
      phoneNumber_id: '',
      errorMessage: ''
    })
  }


  useEffect(() => {
   initFacebookSdk(sessionInfoListener);
  }, []);

  return (
    <FacebookAuthContext.Provider value={{ resetUserData, userData, login, logout }}>
      {children}
    </FacebookAuthContext.Provider>
  );
};

export const useFacebookAuth = (): FacebookAuthContextType => {
  const context = useContext(FacebookAuthContext);
  if (!context) {
    throw new Error('useFacebookAuth must be used within a FacebookAuthProvider');
  }
  return context;
};
