import React, { useState } from 'react';
import axios from 'axios';
import { getAuthCookie } from '../lib/utils/cookieUtils';

interface WebhookURLProps {
  webhookURL: string;
  wabaSndMsgId: string; // Assuming each webhook has a unique ID
}

const WebhookURL: React.FC<WebhookURLProps> = ({ webhookURL, wabaSndMsgId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [url, setUrl] = useState(webhookURL);
  const [error, setError] = useState<string | null>(null);

  const handleEditClick = () => {
    setIsEditing(true);
    setError(null); // Clear any previous error
  };

  const handleSaveClick = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/account/waba/${wabaSndMsgId}/updateWebhook`, { webhookURL: url }, {
        headers: {
            'Authorization': `Bearer ${await getAuthCookie()}`,
            'Content-Type': 'application/json'
        }
      })
      setError(null); // Clear any previous error on successful save
      setIsEditing(false);
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            if (error.response && error.response.status === 400) {
                setError(error.response.data); // Set validation error message
            } else {
                console.error('Error updating webhook URL:', error);
            }
        } else {
            console.error('Error updating webhook', error)
        }
    }
  };

  return (
    <div>
    <div className="flex items-center space-x-2 p-2 rounded-md">
      <span className="font-bold">POST</span>
      <input
        type="text"
        className={`flex-1 p-2 ${isEditing ? 'bg-white' : 'bg-gray-200'} border border-gray-600 rounded-md focus:outline-none`}
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        disabled={!isEditing}
      />
      {isEditing ? (
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none"
          onClick={handleSaveClick}
        >
          Save
        </button>
      ) : (
        <button
          className="bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-gray-700 focus:outline-none"
          onClick={handleEditClick}
        >
          Edit
        </button>
      )}
    </div>
    {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};

export default WebhookURL;
