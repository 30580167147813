import React from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Sidebar from './components/sidebar';
import ProtectedRoute from './customRoutes/protectedRoute';
import SafeGuardAuthRoute from './customRoutes/safeGuardAuthRoute';
import { AuthProvider } from './lib/context/auth';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import Register from './pages/Register';
import ResetPassword from './pages/ResetPassword';
import Overview from './pages/overview';
import WhatsappBusinessAccounts from './pages/whatsappBusinessAccounts';
import WhatsAppBusinessAccountDetails from './pages/whatsappBusinessAccountDetails';

const MainLayout: React.FC = () => {
  const location = useLocation();
  const authRoutes = ['/register', '/login', '/forgot-password', 'reset-password'];

  const wrapSafeGuardAuthRoute = (element: JSX.Element) => {
    return (
      <SafeGuardAuthRoute>
        {element}
      </SafeGuardAuthRoute>
    );
  };

  const wrapProtectedRoute = (element: JSX.Element, postAuthRedirect: string) => {
    return (
      <ProtectedRoute postAuthRedirect={postAuthRedirect}>
        {element}
      </ProtectedRoute>
    );
  };

  return (
    <AuthProvider>
      <div className="flex">
        {!authRoutes.includes(location.pathname) && <Sidebar />}
        <div className="flex-1 p-4 h-screen bg-gray-100">
          <Routes>
            <Route path="/register" element={ wrapSafeGuardAuthRoute(<Register/>)} />
            <Route path="/login" element={ wrapSafeGuardAuthRoute(<Login/>)} />
            <Route path="/forgot-password" element={ wrapSafeGuardAuthRoute(<ForgotPassword/>)} />
            <Route path="/reset-password" element={ wrapSafeGuardAuthRoute(<ResetPassword/>)} />
            <Route path="/dashboard" element={ wrapProtectedRoute( <Overview/>, '/dashboard' ) } />
            <Route path="/whatsapp-business-accounts" element={ wrapProtectedRoute( <WhatsappBusinessAccounts/>, '/whatsapp-business-accounts' ) } />
            <Route path="/whatsapp-business-accounts/:businessId" element={ wrapProtectedRoute( <WhatsAppBusinessAccountDetails/>, '/whatsapp-business-accounts/:businessId' ) } />
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
    </AuthProvider>
  );
};

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <MainLayout />
    </BrowserRouter>
  );
};

export default App;
