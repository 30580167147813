import { Navigate } from 'react-router-dom';
import { useAuth } from '../lib/context/auth';

const SafeGuardAuthRoute = ({ children }: any) => {
    const { user } = useAuth();
    if(user === undefined) {
        // user has default value,
        // if null or data the auth is initialised
        console.log('Auth not initialised');
        return;
    }
    const authenticated = user ? true : false;
    return authenticated ? <Navigate to={`/home`} /> : children;
};

export default SafeGuardAuthRoute;