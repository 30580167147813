import React from 'react';
import { FiLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';

interface WhatsAppBusinessAccountsListProps {
  accounts: WhatsAppBusinessAccount[]
}

const WhatsAppBusinessAccountsList: React.FC<WhatsAppBusinessAccountsListProps> = ({ accounts }) => {


  return (
    <div className="p-4">
      {accounts.map((account) => (
        <div
          key={account.id}
          className="border border-gray-700 p-4 rounded-md mb-4 bg-gray-900"
        >
          <div className="flex items-center">
            <div className="mb-2 pr-4 border-r border-gray-400">
              <Link to={`/whatsapp-business-accounts/${account.id}`} className="text-blue-400 hover:underline">
                {account.name}
              </Link>
            </div>
            <div className="flex items-center mb-2 text-gray-500 pl-4 pr-4 border-r border-gray-400">
              <div className="flex items-center rounded hover:bg-gray-700 ">
                <a
                  href={`https://business.facebook.com/settings/whatsapp-business-accounts/${account.wabaId}?business_id=${account.fbbaId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-1"
                > Facebook Business Manager</a>
                <FiLink className="ml-1"/>
              </div>
            </div>
            <div className="flex items-center mb-2 text-gray-500 pl-4">
              <div className="flex items-center rounded hover:bg-gray-700">
                <a
                  href={`https://business.facebook.com/wa/manage/home/?business_id=${account.fbbaId}&waba_id=${account.wabaId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-1"
                > WhatsApp Business Manager</a>
                <FiLink className="ml-1"/>
              </div>
            </div>
          </div>
          {account.whatsAppPhoneNumbers.length > 0 && (
            <div className="flex mt-2">
              <div className="text-gray-400 font-bold mb-1">Linked WhatsApp Phone Numbers:</div>
              <div>
                {account.whatsAppPhoneNumbers.map((phoneNumber) => (
                  <div className="text-gray-100 pl-2" key={phoneNumber.id}>
                    <span>
                      {phoneNumber.phoneNumber}
                    </span>
                  </div>
                  ))}
              </div>
            </div>
          )}
          <div className="flex mt-2">
            <div className="text-gray-400 font-bold mb-1">Webhook URL:</div>
            <div className="text-gray-100 pl-2">
              <span>
                {account.webhookURL ? account.webhookURL : '-'}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default WhatsAppBusinessAccountsList;
