import { ID, Models } from "appwrite";
import { createContext, useContext, useEffect, useState } from "react";
import { redirect } from "react-router-dom";
import { account, appwriteClient } from "../appwrite";
import { removeAuthCookie, setAuthCookie } from "../utils/cookieUtils";

export interface AuthContextType {
    user: Models.Preferences | null | undefined
    login: (email: string, password: string) => Promise<void>
    logout: () => Promise<void>
    register: (email: string, password: string) => Promise<void>
    forgotPassword: (email: string) => Promise<void>
    updateRecoveryPassword: (userId: string, secret: string, password: string) => Promise<void>
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ((props: any) => {
    const [user, setUser] = useState<Models.Preferences | null | undefined>(undefined);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        try {
            const loggedIn = await account.getSession('current');
            console.log(`Logged in userId :: ${loggedIn.$id}`)
            setUser(loggedIn);
            await setAuthCookie();
        } catch (err) {
            appwriteClient.setJWT('');
            setUser(null);
        }
    }

    async function login(email: string, password: string) {

        const loggedIn = await account.createEmailPasswordSession(email, password);
        console.log(`Logged in userId :: ${loggedIn.$id}`)
        setUser(loggedIn);
        await setAuthCookie()
        redirect('/home');
    }

    async function logout() {
        await account.deleteSession("current");
        setUser(null);
        removeAuthCookie();
        redirect('/login');
    }

    async function register(email: string, password: string) {
        await account.create(ID.unique(), email, password);
        await login(email, password);
    }

    async function forgotPassword(email: string) {
        await account.createRecovery(email, `${process.env.REACT_APP_BASE_URL}/reset-password`);
    }

    async function updateRecoveryPassword(userId: string, secret: string, password: string) {
        await account.updateRecovery(userId, secret, password);
    }

    return (
        <AuthContext.Provider value={{ user: user, login, logout, register, forgotPassword, updateRecoveryPassword }}>
        {props.children}
        </AuthContext.Provider>
    );
});

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (context === null) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};