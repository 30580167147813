import React from 'react';

const LogoWithText: React.FC = () => {

    return (
        <div className="flex justify-center mb-6">
            <img src="/assets/logo-with-brand-transparent-bg-1.5x.png" alt="App logo with text" />
        </div>
    );
};

export default LogoWithText;
