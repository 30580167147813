import { useFormik } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import LogoWithText from '../components/logoWithText';
import { useAuth } from '../lib/context/auth';

const Register: React.FC = () => {
    const { register } = useAuth();
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            confirmPassword: '',
            agree: false,
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Required'),
            password: Yup.string().min(6, 'Password must be at least 6 characters').required('Required'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password')], 'Passwords must match')
                .required('Required'),
            agree: Yup.boolean().oneOf([true], 'You must accept the terms and conditions and privacy policy').required('Required'),
        }),
        onSubmit: async (values) => {
            try {
                await register(values.email, values.password);
            } catch (error) {
                console.error(error);
                alert('Registration failed.');
            }
        },
    });

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 flex-col">
            <LogoWithText />
            <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
                <h1 className="text-xl font-bold mb-4">Register</h1>
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            className="mt-1 p-2 border border-gray-300 rounded w-full"
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
                        ) : null}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            className="mt-1 p-2 border border-gray-300 rounded w-full"
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.password}</div>
                        ) : null}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Confirm Password</label>
                        <input
                            id="confirmPassword"
                            name="confirmPassword"
                            type="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirmPassword}
                            className="mt-1 p-2 border border-gray-300 rounded w-full"
                        />
                        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.confirmPassword}</div>
                        ) : null}
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">
                            <input
                                id="agree"
                                name="agree"
                                type="checkbox"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.agree}
                                className="mr-2"
                            />
                            I agree to the <a href="https://sndmsg.to/terms-and-conditions" className="text-blue-500" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and <a href="https://sndmsg.to/privacy-policy" className="text-blue-500" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                        </label>
                        {formik.touched.agree && formik.errors.agree ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.agree}</div>
                        ) : null}
                    </div>
                    <button type="submit" className="w-full py-2 px-4 bg-black text-white rounded hover:bg-gray-700">Register</button>
                </form>
                <div className="mt-4 flex justify-between">
                    <span>Already have an account? <Link to="/login" className="text-sm text-blue-500">Login</Link></span>
                </div>
            </div>
        </div>
    );
};

export default Register;
