import React, { useState } from 'react';
import { FaSignOutAlt, FaTachometerAlt, FaWhatsapp } from 'react-icons/fa';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { useAuth } from '../lib/context/auth';

const Sidebar: React.FC = () => {
  const { logout } = useAuth();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div className={`bg-black text-white h-screen p-4 relative ${isCollapsed ? 'w-22' : 'w-64'}`}>
      <div className="flex px-4 mb-6">
        {/* <img src="/path/to/logo.png" alt="App Logo" className={`h-12 ${isCollapsed ? 'hidden' : 'block'}`} /> */}
        <img src="/assets/icon-white-bg-512px.png" alt="App Logo" className={`h-8 rounded-full`} />
        <span className={`font-mono text-2xl ml-3 ${isCollapsed ? 'hidden' : 'block'}`}>sndmsg</span>
      </div>
      <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
      <nav>
        <ul className="space-y-4">
          <li>
            <NavLink
              to="/dashboard"
              className="flex items-center px-4 py-2 rounded hover:bg-gray-700"
            //   activeClassName="bg-gray-700"
              data-tooltip-id="overview-tooltip"
            >
              <FaTachometerAlt className="text-2xl" />
              {!isCollapsed && <span className="ml-4">Overview</span>}
            </NavLink>
            {isCollapsed && <Tooltip id="overview-tooltip" place="right" content="Overview" />}
          </li>
          <li>
            <NavLink
              to="/whatsapp-business-accounts"
              className="flex items-center px-4 py-2 rounded hover:bg-gray-700"
            //   activeClassName="bg-gray-700"
              data-tooltip-id="whatsapp-tooltip"
            >
              <FaWhatsapp className="text-2xl" />
              {!isCollapsed && <span className="ml-4">WhatsApp</span>}
            </NavLink>
            {isCollapsed && <Tooltip id="whatsapp-tooltip" place="right" content="WhatsApp" />}
          </li>
          <li>
            <NavLink
              to=''
              onClick={handleLogout}
              className="flex items-center px-4 py-2 rounded hover:bg-gray-700"
            //   activeClassName="bg-gray-700"
              data-tooltip-id="logout-tooltip"
            >
              <FaSignOutAlt className="text-2xl" />
              {!isCollapsed && <span className="ml-4">Logout</span>}
            </NavLink>
            {isCollapsed && <Tooltip id="logout-tooltip" place="right" content="Logout" />}
          </li>
        </ul>
      </nav>
      <button
        className="absolute bottom-4 right-4 flex items-center pr-4 py-2 rounded hover:bg-gray-700"
        onClick={handleToggle}
      >
        {
        isCollapsed
        ? <FiArrowRight className="text-2xl" />
        : <FiArrowLeft className="text-2xl" />}
      </button>
    </div>
  );
};

export default Sidebar;



// import { FaBars, FaRegUser, FaSignOutAlt, FaTachometerAlt, FaTimes, FaWhatsapp } from 'react-icons/fa';
// <img src="/assets/icon-white-bg-512px.png" alt="App Logo" className={`h-8 rounded-full ${isCollapsed ? 'block' : 'hidden'}`} />
// <span className={`font-mono text-2xl ${isCollapsed ? 'hidden' : 'block'}`}>sndmsg</span>
