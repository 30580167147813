import { Account, Client } from 'appwrite';

const appwriteClient = new Client()
    .setEndpoint(process.env.REACT_APP_APPWRITE_ENDPOINT || 'http://localhost/v1') // Your Appwrite endpoint
    .setProject(process.env.REACT_APP_APPWRITE_PROJECT_ID || 'YOUR_PROJECT_ID'); // Your project ID

const account = new Account(appwriteClient);


export { account, appwriteClient };

