import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { getAuthCookie } from '../lib/utils/cookieUtils';
import ApiKeyDisplay from './apiKeyDisplay';

const Account: React.FC = () => {

    const [sid, setSid] = useState<string>('');
    const [apiKey, setApiKey] = useState<string>('');
    const [loading, setLoading] = useState(true);

    const fetchAccount = async() => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/account`, {
                headers: {
                    'Authorization': `Bearer ${await getAuthCookie()}`,
                    'Content-Type': 'application/json'
                }
            })
            setSid(response.data.sid)
            setApiKey(response.data.apiKey)
        } catch (error) {
            console.error('Error fetching API key:', error);
        } finally {
            setLoading(false);
        }
    }

    // const generateApiKey = async () => {
    //     try {
    //         setLoading(true);
    //         const response = await axios.post(`${process.env.REACT_APP_API_URL}/waba/generate-api-key`, null, {
    //             headers: {
    //               'Authorization': `Bearer ${await getAuthCookie()}`,
    //               'Content-Type': 'application/json'
    //             }
    //           })
    //         setApiKey(response.data.apiKey);
    //     } catch (error) {
    //         console.error('Error fetching API key:', error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    // const handleRegenerate = () => {
    //     generateApiKey();
    // };

    useEffect(() => {
        console.log("API Key use effects called .....")
        const fetchData = async () => {
            await fetchAccount()
        }
        fetchData()
      },[])

    return (
        <div>
            {loading ? (
        <p>Loading...</p>
            ) : (
                <>
                    <div className="api-key-display">
                        <div className="flex items-center space-x-2">
                            <label className="font-medium text-gray-400 w-1/12">SID</label>
                            <input
                                type="text"
                                value={sid}
                                readOnly
                                className="bg-gray-700 text-gray-400 w-1/3 px-3 py-2 rounded-md focus:outline-none"
                            />
                        </div>
                        <ApiKeyDisplay apiKey={apiKey} />
                        {/* <button className = "mt-2 border rounded" onClick={handleRegenerate}><span className="p-3">{apiKey ? 'Regenerate API Key' : 'Generate API Key'}</span></button> */}
                    </div>
                </>
      )}
        </div>
    );
};

export default Account;
