declare namespace fb {
    interface AuthResponse {
        code: string;
        userID: string;
        expiresIn: number;
        signedRequest: string;
    }

    interface StatusResponse {
        status: 'connected' | 'not_authorized' | 'unknown';
        authResponse: AuthResponse;
    }

    interface LoginOptions {
        config_id: string
        response_type: string,
        override_default_response_type: boolean,
        extras?: any
    }

    interface InitParams {
        appId: string;
        cookie: boolean;
        xfbml: boolean;
        version: string;
    }

    interface ProfileFields {
        fields: string
    }

    interface APIResponse {
        name: string;
        email: string;
        picture: {
            data: {
                url: string;
            };
        };
    }

    function init(params: InitParams): void;
    function login(callback: (response: StatusResponse) => void, options?: LoginOptions): void;
    function getLoginStatus(callback: (response: StatusResponse) => void): void;
    function logout(callback: (response: StatusResponse) => void): void;
}

declare global {
    interface Window {
        fbAsyncInit: () => void;
        FB: typeof fb;
    }
}

export const initFacebookSdk = (sessionInfoListener: (event: any) => void) => {
    return new Promise<void>((resolve) => {
        window.fbAsyncInit = function () {
            window.FB.init({
                // appId: process.env.REACT_APP_FACEBOOK_APP_ID || '', // Your App ID
                appId: '503342195592476',
                cookie: true,
                xfbml: true,
                version: 'v20.0'
            });

            window.addEventListener('message', sessionInfoListener);
            resolve();
        };

        // Load the SDK asynchronously
        (() => {
            const elId = 'facebook-jssdk';
            const elTag = 'script'
            const scriptsTag = document.getElementsByTagName(elTag)[0];
            if (document.getElementById(elId)) return;
            const newScriptEl = document.createElement(elTag);
            newScriptEl.id = elId;
            newScriptEl.src = 'https://connect.facebook.net/en_US/sdk.js';
            scriptsTag.parentNode?.insertBefore(newScriptEl, scriptsTag);
        })();
    });
};

export const loginWithFacebook = () => {
    return new Promise<fb.StatusResponse>((resolve, reject) => {
        window.FB.login((response) => {
            if (response.authResponse) {
                resolve(response);
            } else {
                console.log('FB Auth failure')
                reject(new Error('User cancelled login or did not fully authorize.'));
            }
        }, {
            // config_id: process.env.REACT_APP_FACEBOOK_WHATSAPP_SIGNUP_CONFIG_ID, // configuration ID goes here
            config_id: '3385380968428431',
            response_type: 'code',    // must be set to 'code' for System User access token
            override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
            extras: {
                setup: {},
                feature: 'whatsapp_embedded_signup',
                // featureType: 'only_waba_sharing', // Bypass phone number selection
                sessionInfoVersion: '2',
            }
        });
    });
};

export const logoutFacebook = () => {
    return new Promise<fb.StatusResponse>((resolve) => {
        window.FB.getLoginStatus(response => {
            if (!response.authResponse) {
                return;
            }
        
            //if we do have a non-null response.session, call FB.logout(),
            //the JS method will log the user out of Facebook and remove any authorization cookies
            window.FB.logout(response => {
                console.log('FB Loggout successfull')
            });
        });
        window.FB.logout((response) => {
            resolve(response);
        });
    });
};
