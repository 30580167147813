import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useFacebookAuth } from '../lib/context/facebookAuth';
import { getAuthCookie } from '../lib/utils/cookieUtils';
import Button from './button';
import LoadingOverlay from './loadingOverlay';

interface AddWABAButtonProps {
  accountAddCallback: () => void
}

const AddWABAButton: React.FC<AddWABAButtonProps> = ({ accountAddCallback }) => {

    const { resetUserData, login, logout, userData } = useFacebookAuth();

    // Overlay states
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const [canCloseOverlay, setCanCloseOverlay] = useState(false);
    const [overlayMessage, setOverlayMessage] = useState('');

    const handleCloseOverlay = () => {
        setIsOverlayOpen(false);
    };

    const sendDataToServer = useCallback(async (accessCode: string, wabaId: string, wabaPhoneId: string) => {
        if (accessCode === null || wabaId === null) return;
        if(isOverlayOpen) {
          setOverlayMessage('Updating WhatsApp Business Account details in our servers');
        }
        const payload: WabaSignupPayload = {
          code: accessCode,
          wabaId: wabaId
        };
        if(wabaPhoneId !== null) {
          payload.wabaPhoneId = wabaPhoneId
        }
        resetUserData() //Resetting to stop call back again incase of any event
        console.log(`Sending data to the server...`)
        console.log(`FB Data :: ${payload}`);
        return axios.post(`${process.env.REACT_APP_API_URL}/account/waba/connect`,
          payload, {
          headers: {
            'Authorization': `Bearer ${await getAuthCookie()}`,
            'Content-Type': 'application/json'
          }
        }).then((response: any) => {
          // console.log(`Response :: ${JSON.stringify(response.data)}`)
          if(response.status === 200 && response.data.accountId) {
            setOverlayMessage('WhatsApp Business Account added successfully');
            accountAddCallback()
          }
          //Logout of facebook account we dont need it
          logout();
        }).catch(error => {
          console.error('Error sending waba signup data to server.')
          console.log(error);
          setOverlayMessage('Error adding WhatsApp Business Account. If re-using already existing number try after some time. Please contact our support for more help!');
        }).finally(() => {
          setCanCloseOverlay(true)
        })
      }, [isOverlayOpen, resetUserData, logout, accountAddCallback]);
    
      useEffect(() => {
        console.log('User data use effect called on change...')
    
        // console.log(`User data :: ${JSON.stringify(userData)}`)
    
        if(userData.errorMessage && isOverlayOpen) {
          setOverlayMessage(userData.errorMessage)
          setCanCloseOverlay(true)
          return
        }
    
        if(userData.accessCode && userData.wabaId && userData.phoneNumber_id && isOverlayOpen) {
          // send the data to server
          sendDataToServer(userData.accessCode, userData.wabaId, userData.phoneNumber_id)
        }
    
        //change wabaConnect state here
      }, [isOverlayOpen, userData, sendDataToServer]);

    const handleAddWABA = () => {
        setOverlayMessage('Onboarding your WhatsApp Business Account. Please don\'t close or refresh this page.');
        setIsOverlayOpen(true);
        setCanCloseOverlay(false);
        login();
      };

    return (
        <div>
            <LoadingOverlay
                message={overlayMessage}
                isOpen={isOverlayOpen}
                canClose={canCloseOverlay}
                onClose={handleCloseOverlay}
            />
            <Button onClick={handleAddWABA}>Add/Update WhatsApp Business Account</Button>
        </div>
    );
};

export default AddWABAButton;
