import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../lib/context/auth';

const ProtectedRoute = ({ children, postAuthRedirect }: any) => {
    const location = useLocation();
    const { user } = useAuth();
    if(user === undefined) {
        // user has default value,
        // if null or data the auth is initialised
        console.log('Auth not initialised');
        return;
    }
    const authenticated = user ? true : false;
    return authenticated ? (
        children
    ) : (
        <Navigate
        to={`/login?redirect=${encodeURIComponent(postAuthRedirect || location.pathname)}`}
        />
    );
};

export default ProtectedRoute;