import { useFormik } from 'formik';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import LogoWithText from '../components/logoWithText';
import { useAuth } from '../lib/context/auth';

const ResetPassword: React.FC = () => {
    const { updateRecoveryPassword } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const userId = query.get('userId');
    const secret = query.get('secret');

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object({
            password: Yup.string().min(6, 'Password must be at least 6 characters').required('Required'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password')], 'Passwords must match')
                .required('Required'),
        }),
        onSubmit: async (values) => {
            if (userId && secret) {
                try {
                    await updateRecoveryPassword(userId, secret, values.password);
                    alert('Password reset successful!');
                    navigate('/login');
                } catch (error) {
                    console.error(error);
                    alert('Password reset failed.');
                }
            } else {
                alert('Invalid password reset link.');
            }
        },
    });

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 flex-col">
            <LogoWithText />
            <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
                <h1 className="text-xl font-bold mb-4">Reset Password</h1>
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">New Password</label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            className="mt-1 p-2 border border-gray-300 rounded w-full"
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.password}</div>
                        ) : null}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Confirm Password</label>
                        <input
                            id="confirmPassword"
                            name="confirmPassword"
                            type="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirmPassword}
                            className="mt-1 p-2 border border-gray-300 rounded w-full"
                        />
                        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.confirmPassword}</div>
                        ) : null}
                    </div>
                    <button type="submit" className="w-full py-2 px-4 bg-black text-white rounded hover:bg-gray-700">Reset Password</button>
                </form>
                <div className="mt-4">
                    <Link to="/login" className="text-sm text-blue-500">Back to Login</Link>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
