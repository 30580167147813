import { useFormik } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import LogoWithText from '../components/logoWithText';
import { useAuth } from '../lib/context/auth';


const ForgotPassword: React.FC = () => {
    const { forgotPassword } = useAuth();
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Required'),
        }),
        onSubmit: async (values) => {
            try {
                await forgotPassword(values.email);
                alert('Password recovery email sent!');
            } catch (error) {
                console.error(error);
                alert('Failed to send recovery email.');
            }
        },
    });

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 flex-col">
            <LogoWithText />
            <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
                <h1 className="text-xl font-bold mb-4">Forgot Password</h1>
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            className="mt-1 p-2 border border-gray-300 rounded w-full"
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
                        ) : null}
                    </div>
                    <button type="submit" className="w-full py-2 px-4 bg-black text-white rounded hover:bg-gray-700">Send Reset Link</button>
                </form>
                <div className="mt-4">
                    <Link to="/login" className="text-sm text-blue-500">Back to Login</Link>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
