import React, { useState } from 'react';
import { FaEye, FaEyeSlash, FaClipboard } from 'react-icons/fa';

interface ApiKeyDisplayProps {
  apiKey: string;
}

const ApiKeyDisplay: React.FC<ApiKeyDisplayProps> = ({ apiKey }) => {
  const [showKey, setShowKey] = useState(false);

  const toggleShowKey = () => {
    setShowKey(!showKey);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(apiKey);
    alert('API key copied to clipboard!');
  };

  return (
    <div className="flex items-center space-x-2 mt-2">
      <label className="font-medium text-gray-400 w-1/12">API Key</label>
      <input
        type={showKey ? 'text' : 'password'}
        value={showKey ? apiKey : '*'.repeat(apiKey.length)}
        readOnly
        className="bg-gray-700 text-gray-400 w-1/3 px-3 py-2 rounded-md focus:outline-none"
      />
      <button onClick={toggleShowKey} className="focus:outline-none">
        {showKey ? <FaEyeSlash /> : <FaEye />}
      </button>
      <button onClick={copyToClipboard} className="focus:outline-none">
        <FaClipboard />
      </button>
    </div>
  );
};

export default ApiKeyDisplay;
