import React, { useEffect, useState } from 'react';
import { FacebookAuthProvider } from '../lib/context/facebookAuth';
import { getAuthCookie } from '../lib/utils/cookieUtils';
import axios from 'axios';
import AddWABAButton from '../components/addWABAButton';
import WhatsAppBusinessAccountsList from '../components/whatsappBusinessAccountList';

const WhatsappBusinessAccounts: React.FC = () => {
    
    const [loading, setLoading] = useState<boolean>(true);
    const [accounts, setAccounts] = useState<WhatsAppBusinessAccount[]>([]);

    const updateAccount = () => {
        // Fetch WhatsAppBusinessAccounts
        getAuthCookie().then((authToken) => {
            axios.get(`${process.env.REACT_APP_API_URL}/account/waba`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authToken}`
                }
            }).then(response => {
                setAccounts(response.data);
                setLoading(false);
            }).catch(error => {
                console.error('Error fetching WhatsApp business accounts:', error);
                setLoading(false);
            });
        })
    }

    useEffect(() => {
        // Update the accounts list
        updateAccount()
    }, []);

    return (
        <div>
            <div className="flex justify-between">
                <h2 className="text-2xl font-bold">WhatsApp Business Accounts</h2>
                <FacebookAuthProvider>
                    <AddWABAButton accountAddCallback={updateAccount} />
                </FacebookAuthProvider>
            </div>
            <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
            {loading ? (
                <div className="text-center text-gray-500">Loading...</div>
            ) : (
                <WhatsAppBusinessAccountsList accounts={accounts}/>
            )}
            {/* <div className="container mx-auto p-4 h-screen overflow-hidden">
                <div className="flex flex-col h-full overflow-y-auto">
                    <Card title='Whatsapp'>
                        <FacebookAuthProvider>
                            <div className="flex items-center">
                                <FacebookLogin />
                            </div>
                        </FacebookAuthProvider>
                    </Card>
                </div>
            </div> */}
        </div>
    );
};

export default WhatsappBusinessAccounts;
