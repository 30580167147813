import React from 'react';
import Card from '../components/card';
import Account from '../components/account';

const Overview: React.FC = () => {
    return (
        <div>
            <h2 className="text-2xl font-bold">Overview</h2>
            <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
            <Card title="Account">
                <Account />
            </Card>
        </div>
    );
};

export default Overview;
