import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { getAuthCookie } from '../lib/utils/cookieUtils';
import WebhookURL from '../components/webhookURL';

interface WhatsAppPhoneNumberDetails {
    name: string;
    phoneNumber: string;
    qualityRating: string;
    status: string;
    messageLimit: string;
    id: string;
}

interface AccountDetails {
  id: string;
  name: string;
  fbbaId: string;
  wabaId: string;
  webhookURL: string
  whatsAppPhoneNumbers: WhatsAppPhoneNumberDetails[];
}

const WhatsAppBusinessAccountDetails: React.FC = () => {
  const { businessId } = useParams<{ businessId: string }>();
  const [accountDetails, setAccountDetails] = useState<AccountDetails | null>(null);

  useEffect(() => {
    getAuthCookie().then((authToken) => {
        axios.get(`${process.env.REACT_APP_API_URL}/account/waba/${businessId}`, {
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
          setAccountDetails(response.data);
        });
    })
  }, [businessId]);

  if (!accountDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-4">
        <div className="flex justify-between">
            <h2 className="text-2xl font-bold">WhatsApp Business Account Details</h2>
        </div>
        <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
      <nav className="mb-4">
        <Link to="/whatsapp-business-accounts" className="text-blue-400 hover:underline">
          WhatsApp Business Accounts
        </Link>
        {' / '}
        <span>{accountDetails.name}</span>
      </nav>

      <div className="overflow-x-auto mb-4">
        <table className="min-w-full bg-white text-black border border-black mb-4">
          <colgroup>
            <col className='bg-gray-300 w-1/4'/>
            <col className='bg-gray-100'/>
          </colgroup>
          <tbody>
            <tr>
              <td className="px-4 py-2 border-b border-black font-bold">Name</td>
              <td className="px-4 py-2 border-b border-black">{accountDetails.name}</td>
            </tr>
            <tr>
              <td className="px-4 py-2 border-b border-black font-bold">Facebook Business ID</td>
              <td className="px-4 py-2 border-b border-black">
                <a
                  href={`https://business.facebook.com/settings/whatsapp-business-accounts/${accountDetails.wabaId}?business_id=${accountDetails.fbbaId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:underline"
                > {accountDetails.fbbaId} </a>
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2 border-b border-black font-bold">WhatsApp Business ID</td>
              <td className="px-4 py-2 border-b border-black">
                <a
                  href={`https://business.facebook.com/wa/manage/home/?business_id=${accountDetails.fbbaId}&waba_id=${accountDetails.wabaId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:underline"
                >{accountDetails.wabaId}</a>
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2 border-b border-black font-bold">Webhook</td>
              <td className="px-4 py-2 border-b border-black">
                <WebhookURL webhookURL={accountDetails.webhookURL} wabaSndMsgId={accountDetails.id}/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h3 className="text-xl font-bold mb-4">Phone Numbers</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white text-black border border-black">
          <thead>
            <tr>
              <th className="px-4 py-2 border-b border-black bg-gray-300">Verified Name</th>
              <th className="px-4 py-2 border-b border-black bg-gray-300">Phone Number</th>
              <th className="px-4 py-2 border-b border-black bg-gray-300">Quality Rating</th>
              <th className="px-4 py-2 border-b border-black bg-gray-300">Status</th>
              <th className="px-4 py-2 border-b border-black bg-gray-300">Messaging Limit</th>
            </tr>
          </thead>
          <tbody>
            {accountDetails.whatsAppPhoneNumbers.map((phoneNumber) => (
              <tr key={phoneNumber.id} className="bg-gray-100">
                <td className="px-4 py-2 border-b border-black text-center">{phoneNumber.name}</td>
                <td className="px-4 py-2 border-b border-black text-center">{phoneNumber.phoneNumber}</td>
                <td className="px-4 py-2 border-b border-black text-center">{phoneNumber.qualityRating}</td>
                <td className="px-4 py-2 border-b border-black text-center">{phoneNumber.status}</td>
                <td className="px-4 py-2 border-b border-black text-center">{phoneNumber.messageLimit}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WhatsAppBusinessAccountDetails;
