import Cookies from "js-cookie";
import { account } from "../appwrite";

const SESSION_KEY = process.env.REACT_APP_SESSION_KEY || 'session'

export const setAuthCookie = async () => {
    try {
        const { jwt } = await account.createJWT();
        if (!jwt) {
            throw new Error('JWT is missing....')
        }
        //Appwrite JWT expires in 15mins, so setting 14 mins as expiry for this JWT
        Cookies.set(SESSION_KEY, jwt, {expires: (1 / 1440) * 14 });
    } catch (error: any) {
        console.error(`Error setting auth cookie`)
        console.log(`Exception :: ${error.name}, ${error.message} \nStack :: ${error.stack}`)
        // Reload the current page to login again
        window.location.reload()
    }
}

export const getAuthCookie = async () => {
    let authToken = Cookies.get(SESSION_KEY)
    if(!authToken) {
        // Cookie expired - Retry to get to JWT
        console.log('Generating new JWT....')
        await setAuthCookie()
        authToken = Cookies.get(SESSION_KEY)
        if(!authToken) {
            console.error('Auth Token not found after refresh...')
        }
    }
    return authToken
}

export const removeAuthCookie = () => {
    Cookies.remove(SESSION_KEY);
}