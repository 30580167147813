// import React from 'react';

// interface LoadingOverlayProps {
//   isVisible: boolean;
// }

// const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ isVisible }) => {
//   if (!isVisible) {
//     return null;
//   }

//   return (
//     <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
//       <div className="text-white text-lg">Connecting with Facebook...</div>
//     </div>
//   );
// };

// export default LoadingOverlay;

import React from 'react';
import ReactDOM from 'react-dom';
import { FaTimes } from 'react-icons/fa';

interface LoadingOverlayProps {
  message: string;
  isOpen: boolean;
  canClose: boolean;
  onClose?: () => void;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ message, isOpen, canClose, onClose }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50">
      <div className="relative bg-white p-6 rounded-lg shadow-lg w-80 text-center">
        {!canClose && (
          <div className="flex items-center justify-center mb-4">
            <div className="w-12 h-12 border-4 border-t-transparent border-blue-500 border-solid rounded-full animate-spin"></div>
          </div>
        )}
        <p className="mb-4 text-gray-700">{message}</p>
        {canClose && (
          <button
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
            onClick={onClose}
          >
            <FaTimes />
          </button>
        )}
      </div>
    </div>,
    document.body
  );
};

export default LoadingOverlay;